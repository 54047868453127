<template>
  <div class="table-view">
    <div class="table-view-content">
      <!-- <div class="row">
        <div class="col mb-1">
          <tirscript3-dropdown
            style="max-width: 80px"
            :items="countOnPageItems"
          ></tirscript3-dropdown>
        </div>
      </div> -->

      <bs-row>
        <bs-col>
          <tirscript3-loading :loading="isLoading" />
          <slot name="content" :items="innerItems"></slot>
        </bs-col>
      </bs-row>
      <div
        v-show="!noPagination && innerItems && innerItems.length > 0"
        class="panel"
      >
        <div class="row align-items-center">
          <div class="col-auto ml-auto">
            <tirscript3-pagination
              ref="pagination"
              :size="countPerPage"
              :count="totalItems"
              class="mt-4"
              @onChange="onPaginationChanged"
            >
            </tirscript3-pagination>
          </div>
        </div>
      </div>
      <div
        class="row"
        v-if="
          !noPagination &&
          innerItems &&
          innerItems.length == 0 &&
          isLoading == false
        "
      >
        <div class="pb-2 pt-4 col-12" style="text-align: center">
          Нет данных
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import DefaultModel from "tirscript3-component-models/src/defaultModel";
import UiPaginationComponent from "@/views/components/tirscript3-table/tirscript3-pagination.vue";
import ListViewItem from "tirscript3-list-view/src/ListViewItem";
import PaginationResponse from "@dataSource/PaginationHelper/Lib/PageResponse";
import PageRequest from "@dataSource/PaginationHelper/Lib/PageRequest";

@Options<TableView>({
  name: "TableView",
})
export default class TableView extends Vue {
  @Prop({ required: true }) getDataFuncAsync: Function;
  @Prop({ default: false }) noPagination: boolean;
  @Prop() filter: {};

  innerFilter: PageRequest = { Skip: 0, Take: 20 };

  isLoading = false;
  totalItems = 0;
  currentPage = 1;
  innerItems: any[] = [];

  hasFilter = false;

  countPerPage = 10;
  pagination = true;
  search = "";
  countOnPageItems = [];

  @Watch("filter", { deep: true })
  onFilter() {
    this.refresh();
  }
  mounted() {
    this.countOnPageItems = [
      new ListViewItem(new DefaultModel(1, "10"), true, false),
      new ListViewItem(new DefaultModel(2, "20"), false, false),
      new ListViewItem(new DefaultModel(3, "50"), false, false),
      new ListViewItem(new DefaultModel(4, "100"), false, false),
    ];
  }
  async created() {
    await this.refresh();
  }

  sort(sortName: string, sortType: string) {
    // if (sortName != "" && this.innerFilter.Sort == null) {
    //   this.innerFilter.Sort = { FieldName: "", Desc: false };
    // }
    // this.innerFilter.Sort.FieldName = sortName;
    // this.innerFilter.Sort.Desc = sortType == "1";
    // (this.$refs.pagination as UiPaginationComponent).currentPage = 1;

    this.refresh();
  }

  async refresh() {
    this.isLoading = true;
    let response;
    // if (this.innerFilter.Sort && this.innerFilter.Sort.FieldName == "") {
    //   this.innerFilter.Sort = null;
    // }

    try {
      response = await this.getDataFuncAsync(this.innerFilter);
    } catch (error) {
    } finally {
      this.isLoading = false;
    }

    this.totalItems = response.Count;
    this.innerItems = response.Items;
  }

  get pageSize(): number {
    const x = this.innerFilter.Take;
    return x;
  }

  resetPagination() {
    this.innerFilter.Skip = 0;
    this.currentPage = 1;
    this.totalItems = 0;
  }
  onSort(sortName: string, sortType: string) {
    // console.log("sortType", sortType);
    // this.resetPagination();
    // this.filter.Sort = new SortRequest({
    //   FieldName: sortName,
    //   Desc: sortType == "1",
    // });
    // this.refresh();
  }

  onChangePagePerPage(item: ListViewItem<DefaultModel>[]) {
    this.innerFilter.Take = Number((item[0].Value as DefaultModel).name);
    this.resetPagination();
    this.refresh();
  }

  onPaginationChanged(currentPage: number) {
    this.currentPage = currentPage;
    this.innerFilter.Skip = (currentPage - 1) * this.innerFilter.Take;
    this.refresh();
  }
}
</script>
<style lang="less">
@BaseColor: rgb(34, 26, 58);
.table-view {
  background: white;
  // height: 100%;
  label {
    font-size: 13px;
  }
  h1 {
    font-size: 18px;
    line-height: 22px;
    color: #262838;
    font-weight: 400;
  }
  .page-header {
    display: flex;
    border-bottom: 1px solid @BaseColor;
    padding: 18px 26px;
    font-size: 18px;
    line-height: 22px;
    color: #262838;
    padding-bottom: 12px;
    align-items: center;
    margin-bottom: 10px;
    min-height: 69px;

    .page-header__bnts {
      margin-left: auto;
      .tirscript3-button {
        height: 36px;
        font-size: 1rem;
      }
    }
  }
  .page-content {
    margin: 30px;
  }
  .page-content__panel {
    margin-bottom: 10px;
    background: #f8f8f8;
    padding: 8px 16px;
  }
  .ui-input {
    display: flex;
    input {
      background: #fcfcfc;
      font-family: ProximaNova;
      border: 1px solid #d2d2d2;
      box-sizing: border-box;
      padding: 5px 10px 5px 10px;
      font-size: 14px;
      width: 100%;
      &::placeholder {
        color: #787878;
      }
      &:focus {
        outline: none;
        border: solid 1px @BaseColor;
      }
    }
    .ui-input__btn {
      background: @BaseColor;
      min-width: 27px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      cursor: pointer;
    }
  }
  .ui-textarea {
    background: #fff;
    border: 1px solid #d2d2d2;
    font-size: 14px;
    padding: 10px;
    font-family: ProximaNova;
    resize: none;

    &:focus {
      border: 1px solid @BaseColor;
    }
  }
  .tirscript3-dropdown {
    ui-input input:focus &:focus {
      .ui-select {
        border: 1px solid @BaseColor;
      }
      .tirscript3-box-menu {
        border: 1px solid @BaseColor;
      }
    }

    .tirscript3-box-menu {
      background: #fff;
      border: 1px solid #d2d2d2;
      top: 1px;
    }
    .ui-list-view-item.selected {
      background: #e2e2e2;
    }
    .ui-list-view-item:hover {
      background: #e2e2e2;
      color: #000;
      font-weight: 600;
    }
  }
  .pointer {
    cursor: pointer;
  }
  @-webkit-keyframes fadeInOut {
    0% {
      opacity: 0;
      top: -0.5em;
    }
    25% {
      opacity: 1;
      top: 0;
    }
    75% {
      opacity: 0;
      top: 0.5em;
    }
    100% {
      opacity: 0;
      top: 0.5em;
    }
  }
  @keyframes fadeInOut {
    0% {
      opacity: 0;
      top: -0.5em;
    }
    25% {
      opacity: 1;
      top: 0;
    }
    75% {
      opacity: 0;
      top: 0.5em;
    }
    100% {
      opacity: 0;
      top: 0.5em;
    }
  }
  .add {
    .add__btn::before {
      color: @BaseColor;
      cursor: pointer;
    }
    .add__text {
      text-decoration: underline;
      cursor: pointer;
      color: #000;
    }
  }
  input.tirscript3-checkbox {
    transform: scale(1.3);
    &:checked {
    }
  }
  .ui-date-picker {
    input {
      border: 1px solid #d2d2d2;
    }
    input,
    input:focus {
      background: #fff;
      color: #414351;
    }
    input:focus {
      border: 1px solid @BaseColor;
    }
    .icon {
      color: #414351;
    }
    .date-time {
      background: #fff;
      color: #414351;
      border: 1px solid @BaseColor;
      transition: all 1s;
      .navigation {
        color: #414351;
        .title_date-picker {
          transition: all 1s;
        }
        .title_date-picker:hover {
          background: @BaseColor;
          color: #fff;
        }
        .backward,
        .next {
          transition: all 1s;
        }
        .backward:hover,
        .next:hover {
          background: @BaseColor;
          color: #fff;
        }
      }
      .minute,
      .hour {
        > div {
          transition: all 1s;
        }
      }
      .minute:hover,
      .hour:hover {
        > div {
          background: @BaseColor;
          color: #fff;
        }
      }
    }
  }
  .icon-apteka-pencil,
  .icon-apteka-delete {
    color: #9e9e9e;
    transition: all 0.5s;
    &:hover {
      color: unset;

      text-shadow: 1px 1px 2px black, 0 0 1em @BaseColor;
    }
  }
  .ui-input {
    .invalid {
      border-color: #dc3545;
    }
  }
  .icon {
    margin-right: 0;
  }
  label {
    line-height: 1.5rem;
  }
}
</style>
