<template>
  <div v-if="filter" class="logs-view">
    <tirscript3-loading :loading="isLoading"></tirscript3-loading>
    <tirscript3-table
      :items="items"
      template-columns="auto 250px auto"
      style="overflow-x: auto"
    >
      <template #header>
        <tirscript3-table-header-item>Тип</tirscript3-table-header-item>
        <tirscript3-table-header-item
          >Дата мониторинга</tirscript3-table-header-item
        >
        <tirscript3-table-header-item> Данные</tirscript3-table-header-item>
      </template>
      <template #body="data">
        <tirscript3-table-body-item>{{
          getHardwareLogType(data.item.Type)
        }}</tirscript3-table-body-item>
        <tirscript3-table-body-item>{{
          m(data.item.DateMonitoring)
        }}</tirscript3-table-body-item>
        <tirscript3-table-body-item>{{
          data.item.Value
        }}</tirscript3-table-body-item>
        <tirscript3-table-body-item>
          <div class="detale" @click="onReadMore(data.item.RequestId)">
            детали...
          </div>
        </tirscript3-table-body-item>
      </template>
    </tirscript3-table>
    <!-- <div v-show="!noPagination && items && items.length > 0" class="panel">
      <div class="row align-items-center">
        <div class="col-auto ml-auto">
          <tirscript3-pagination
            ref="pagination"
            :size="page.Take"
            :count="count"
            class="mt-4"
            @onChange="onPaginationChanged"
          >
          </tirscript3-pagination>
        </div>
      </div>
    </div> -->

    <div
      class="row"
      v-if="!noPagination && items && items.length == 0 && isLoading == false"
    >
      <div class="pb-2 pt-4 col-12" style="text-align: center">Нет данных</div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import TableView from "@views/page/components/table-view.vue";
import PageRequest from "@dataSource/PaginationHelper/Lib/PageRequest";
import moment from "moment";
import FilterModel from "../../components/filter-model";
import GetHardwareLogsRequest from "@/dataSource/api/LoggerMicroService/Services/Models/Request/LogGetterService/GetHardwareLogsRequest";
import GetHardwareLogsResponse from "@/dataSource/api/LoggerMicroService/Services/Models/Response/LogGetterService/GetHardwareLogsResponse";
@Options({
  name: "HardwareLogsView",
  components: {
    TableView,
  },
})
export default class HardwareLogsView extends Vue {
  @Prop({ default: new FilterModel() }) filter: FilterModel;
  innerFilter: GetHardwareLogsRequest;
  // items: HardwareMonitoringData[] = [];
  page: PageRequest = { Take: 20, Skip: 0 };
  count = 0;
  noPagination = false;
  isLoading = false;

  @Watch("filter", { deep: true })
  onFilter() {
    this.refresh();
  }

  m(value: Date) {
    return moment(value).format("DD.MM.YYYY hh:mm");
  }
  mounted() {
    this.refresh();
  }

  async refresh() {
    if (!this.filter) {
      return;
    }
    let response: GetHardwareLogsResponse = null;

    this.isLoading = true;
    // this.innerFilter.ToDate = (this.filter as any).DateTo;
    // this.innerFilter.FromDate = (this.filter as any).DateFrom;

    this.innerFilter = {
      ProjectLogGroupId: this.filter.ProjectLogGroupId,
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Type: this.filter.HardwareLogsType,
    };
    try {
      response = await this.$api.LogGetterService.getHardwareLogsAsync(
        this.innerFilter
      );
    } catch (error) {
      console.log(`error:`, error);
      this.isLoading = false;
    }
    this.isLoading = false;

    //   this.items = response.Data.map((item) => ({
    //     Type: item.Type,
    //     Date: item.Date,
    //     Value: item.Value,
    //   }));
    // }
    //событие срабатывет при изменении пагинации при просмотре
    //   onPaginationChanged(page: number) {
    //     let skip = (page - 1) * this.page.Take;
    //     if (this.page.Skip === skip) {
    //       return;
    //     }

    //     this.page.Skip = skip;
    //     this.refresh();
    // //   }
    //   getIntervalTime(dateStart: Date, dateEnd: Date): number {
    //     return new Date(dateEnd).getTime() - new Date(dateStart).getTime();
    //   }
    //   onReadMore(id: string) {
    //     console.log(`id=`, id);
    //   }
  }
}
</script>

<style lang="less">
.logs-view {
  .detale {
    color: blue;
    cursor: pointer;
  }
}

.logs-view .tirscript3-table .tirscript3-table-header-item,
.logs-view .tirscript3-table .tirscript3-table-body-item {
  padding: 0 10px;
  justify-content: center;
  text-align: center;
}
</style>